import './App.scss';
import Layout from './Components/Layout/Layout';

function App() {
  return (
    <div className="container fluid appWrapper">
        <Layout/>
    </div>
  );
}

export default App;
