import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService";
import * as apiUrl from '../apiUrl';

async function submitGridsAdsApiCall(adsArrayList){
    // adsArrayList--> it has to be mappedTransformed for Db
    const formatedLoad = {
        "scheduling_details":adsArrayList
    }
    try {
        const response = await httpService.postCallMethod(apiUrl.submitAds, formatedLoad)
        return response;
    } catch (error) {
        throw error;
    }

}

function* submitAdsWithGrids(action){
    try {
        const result = yield call(()=>submitGridsAdsApiCall(action?.payload))
        yield put({type: 'PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_SUCCESS', successInSubmit : result})

    } catch (error) {
        yield put({type: 'PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_FAILED', errorInSubmitting : error})
    }
}

function* submitalladspositionSaga(){
    yield takeEvery('PRIVATE_DB_SUBMIT_ADS_ALL_TYPES', submitAdsWithGrids)
}

export default submitalladspositionSaga; 