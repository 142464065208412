//getCall
const getCallMethod =async (apiEndPoint)=>{
    try {
        const response = await fetch(apiEndPoint, {
            method: 'GET',
            headers:{
                'Content-Type': 'application/json',  
                // 'access_token': localStorage.getItem('sid')
            }
        })
        if(response.status === 200){ 
            return response.json()
         }else{
            const errorResponse = await response.json();
            throw errorResponse; // Throw the error response object
         }
    } catch (err) {
        throw err
    }
}


//postCall
const postCallMethod = async(apiEndPoint, payload)=>{
    try {
        const response = await fetch(apiEndPoint,{
            method: 'POST',
            headers:{
                'Content-Type': 'application/json',  
                // 'access_token': localStorage.getItem('sid')
            },
            body: JSON.stringify(payload)
        })
        if(response.status === 200){
            return response.json()
        }else{
            const errorResponse = await response.json();
            throw errorResponse; // Throw the error response object
        }
        
    } catch (err) {
        throw err
    }
    
}

const httpService= {
    getCallMethod,
    postCallMethod
}
export default httpService; 