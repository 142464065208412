import * as type from "../Constants/adlist";

const initailState = {
  pageHavingHeader: "", //withHeader-1 or else anything
  pageSize: {
    width: "",
    height: "",
  },
  adListedWithoutGrid: [], //adList
  adPositionedWithGrid: [], //adPositioned
  //finalSubmiting
  adsMixinDataTransformedForPost: [],
  submitAllAdsLoading: false,
  submitAllAdsSuccess: null,
  submitAllAdsError: null,
};

const appendRequiredFieldsForModule = (filteredArray) => {
  //transformBackednd array to give componenets requirement
  const returnTransformedArray = filteredArray?.map((el) => {
    const [h, w] = el?.size?.split("x");
    const height = +(h + "00") / 2; //👈SizeHeight
    const width = +(w + "00") / 2; //👈SizeWidth
    const name = el?.position_name;
    const gridX = el?.dummy_chart_position_x
      ? Number(el?.dummy_chart_position_x)
      : ASSIGN_DEFAULT_GRID?.gridX;
    const gridY = el?.dummy_chart_position_y
      ? Number(el?.dummy_chart_position_y)
      : ASSIGN_DEFAULT_GRID?.gridY;
    const appendRequiredFieldForComponenets = {
      height,
      width,
      name,
      gridX,
      gridY,
    };
    const element = { ...el, ...appendRequiredFieldForComponenets };
    return element;
  });
  return returnTransformedArray;
};

const ASSIGN_DEFAULT_GRID = {
  //default-grid position for new ads when moving to adPositioned colums
  gridX: 470,
  gridY: 0,
};

export default function privatedbstate(state = initailState, action) {
  switch (action.type) {
    case type.PRIVATE_DB_SEND_COPY_FROM_FETCHED_POST:
      const filterAdsWithoutGrids = action?.copyFetchedAdsList?.filter(
        (el) => !el?.dummy_chart_position_x && !el?.dummy_chart_position_y
      );

      const filterAdsWithGrids = action?.copyFetchedAdsList?.filter(
        (el) => el?.dummy_chart_position_x && el?.dummy_chart_position_y
      );

      const pageDecider = action?.copyFetchedAdsList?.find((el) =>
        el?.page_name?.includes("1")
      );
      return {
        ...state,
        adListedWithoutGrid: appendRequiredFieldsForModule(
          filterAdsWithoutGrids
        ),
        adPositionedWithGrid: appendRequiredFieldsForModule(filterAdsWithGrids), //here need to append some field grid, width, height , name will be required
        pageHavingHeader: pageDecider ? true : false,
      };

    case type.PRIVATE_DB_MOVE_ADLIST_TO_AD_POSITION:
      const findIndexOfPayloadAdList = state?.adListedWithoutGrid?.findIndex(
        (el) => el?.id === action?.payload?.id
      );
      const updatedAdlist = [...state?.adListedWithoutGrid];
      updatedAdlist.splice(findIndexOfPayloadAdList, 1);
      return {
        ...state,
        adListedWithoutGrid: updatedAdlist,
        adPositionedWithGrid: state?.adPositionedWithGrid?.concat(
          action?.payload
        ),
        // adsMixinDataTransformedForPost: [] //cruscialStore--has to be submitAction
      };
    case type.PRIVATE_DB_MOVE_AD_POSITION_TO_ADLIST:
      const findIndexOfPayloadAdPositioned =
        state?.adPositionedWithGrid?.findIndex(
          (el) => el?.id === action?.payload?.id
        );
      const updatedAdPositionedlist = [...state?.adPositionedWithGrid];
      updatedAdPositionedlist.splice(findIndexOfPayloadAdPositioned, 1);
      return {
        ...state,
        adListedWithoutGrid: state?.adListedWithoutGrid?.concat(
          action?.payload
        ),
        adPositionedWithGrid: updatedAdPositionedlist,
        // adsMixinDataTransformedForPost: [] //cruscialStore--has to be submitAction
      };

    //submitingRelated
    case type.PRIVATE_DB_SUBMIT_ADS_ALL_TYPES:
      return {
        ...state,
        submitAllAdsLoading: true,
        adsMixinDataTransformedForPost: action?.payload,
      };
    case type.PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_SUCCESS:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: action?.successInSubmit,
        submitAllAdsError: null,
      };
    case type.PRIVATE_DB_SUBMIT_ADS_ALL_TYPES_FAILED:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: action?.errorInSubmitting,
      };

    case type.PRIVATE_DB_SUBMIT_SUCCESS_ERROR_CLEANUP:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: null,
      };

    default:
      return state;
  }
}
