import { call, takeEvery, put } from "redux-saga/effects";
import httpService from '../httpService';
import * as apiUrl from '../apiUrl';
async function userloginApiCall(payload) {
  try {
    const response = httpService.postCallMethod(apiUrl.loginApi, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

function* userloggingIn(action) {
  try {
    const fetchedUserResult = yield call(() => userloginApiCall(action?.payload));
    yield put({ type: "USER_LOGIN_SUCCESS", fetchedUserResult });
  } catch (error) {
    yield put({ type: "USER_LOGIN_FAILED", fetchedUserError: "Something went wrong. Please try again later." });
  }
}

export default function* loginuserSaga() {
  yield takeEvery("USER_LOGIN", userloggingIn);
}
