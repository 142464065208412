import { memo, useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Draggable from "react-draggable";
import TemplateThumbanails from "../TemplateThumbanails/TemplateThumbanails";
import { findTheThumbPositions, ratioCalculte } from "../../Utils/Utils";
import * as constant from "../../CONSTANT";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderThumb.scss";
import { useSelector } from "react-redux";

const SliderThumb = ({
  newsPages,
  dropdownCategoryValues,
  dropDownChangeFromNewsPaperGrid,
  thumbNailAds,
  userClickDone,
  functForUserClick,
  regionSlide,
}) => {
  const settingsThumbs = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
  };

  const sliderRef = useRef();
  const refDrags = useRef(null);
  const [smTemplate, setSmTemplate] = useState([]);
  const { fetchMetaDataInitalDataParams } = useSelector(
    (state) => state.fetchinitialmetadata
  );

  const seletActionOfThumbNail = (e, thumbPageVal, thumbTemplateValue) => {
    const pageNdTemplate = {
      page: thumbPageVal,
      template: thumbTemplateValue,
    };
    dropDownChangeFromNewsPaperGrid(pageNdTemplate); //callBackToThe Parent module to updatete the existing Template dropdown
    functForUserClick();
  };

  const moveSlider = (pageValue) => {
    //assignCondn👇if the match happens
    sliderRef.current.slickGoTo(pageValue - 1, false);
  };
  useEffect(() => {
    if (dropdownCategoryValues?.page?.value) {
      const pageVal = dropdownCategoryValues?.page;
      const dragThumbnailsData = thumbNailAds?.filter((item) => {
        return item?.page === pageVal?.value;
      });
      const pageAdsItem = dragThumbnailsData?.find((ad) => ad?.page);
      const smTemplateFind = smTemplate?.find(
        (s) => s?.value === pageAdsItem?.adv_template_id
      );
      const pageNdTemplate = {
        page: pageVal,
        template: smTemplateFind,
      };
      dropDownChangeFromNewsPaperGrid(pageNdTemplate);
      moveSlider(dropdownCategoryValues?.page?.value);
    }
  }, [
    dropdownCategoryValues?.page?.value,
    dropDownChangeFromNewsPaperGrid,
    dropdownCategoryValues?.page,
    thumbNailAds,
    smTemplate,
  ]);

  useEffect(() => {
    const optionTemplateTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "adv_template"
    ]?.map((el) => ({
      ...el,
      label: el?.template_name,
      value: el?.template_id,
    }));
    setSmTemplate(optionTemplateTransform);
  }, [fetchMetaDataInitalDataParams]);

  return (
    <div className="newsSlider containerThumb">
      <Slider {...settingsThumbs} ref={sliderRef}>
        {newsPages?.map((pageItem) => {
          const dragThumbnailsData = thumbNailAds?.filter((item) => {
            return item?.page === pageItem?.value;
          });
          const pageAdsItem = dragThumbnailsData?.find((ad) => ad?.page);
          const smTemplateFind = smTemplate?.find(
            (s) => s?.value === pageAdsItem?.adv_template_id
          );
          return (
            <div className="thumbOuter" key={pageItem?.value}>
              <div
                className={`thumbnail ${
                  userClickDone &&
                  pageItem?.value === dropdownCategoryValues?.page?.value
                    ? `selectedThumb`
                    : ``
                }`}
                onClick={(e) => {
                  seletActionOfThumbNail(
                    e,
                    pageItem,
                    smTemplateFind,
                    dragThumbnailsData
                  );
                }}
                style={{
                  width: constant.THUMBNAIL_SLIDER_WIDTH + "px",
                  height: constant.THUMBNAIL_SLIDER_HEIGHT + "px",
                }}
              >
                <div>
                  <TemplateThumbanails
                    propTemplate={
                      smTemplateFind ??
                      smTemplate?.find((option) => option?.template_default_all)
                    }
                    adsRef={pageAdsItem}
                    location={regionSlide}
                    page_No={pageAdsItem?.page ?? pageItem?.value}
                  />
                </div>
                <div className="dragsPosn">
                  {dragThumbnailsData?.map((ls, i) => {
                    const thumbGrid = findTheThumbPositions(
                      ls?.gridX,
                      ls?.gridY
                    );
                    return (
                      <Draggable
                        bounds="parent"
                        position={{
                          x: thumbGrid?.newX,
                          y: thumbGrid?.newY,
                        }}
                        defaultPosition={{
                          x: thumbGrid?.newX,
                          y: thumbGrid?.newY,
                        }}
                        disabled
                        key={ls?.gridX + ls?.gridY + i}
                        nodeRef={refDrags}
                      >
                        <div
                          style={{
                            position: "absolute",
                            backgroundColor: "#cdebf9",
                            border: "1px solid #78b7d3",
                            width: `${ratioCalculte(
                              ls?.width,
                              constant.RATIO_THUMBNAIL
                            )}px`,
                            height: `${ratioCalculte(
                              ls?.height,
                              constant.RATIO_THUMBNAIL
                            )}px`,
                          }}
                          ref={refDrags}
                        >
                          <div className="mx-auto text-center">{ls?.id}</div>
                        </div>
                      </Draggable>
                    );
                  })}
                </div>
              </div>
              <span>{pageItem?.label}</span>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default memo(SliderThumb);
