import useAuthStatus from "../CustomHooks/useAuthStatus";

export default function ProtectedRoute({ children }) {
  const { isLoggedin } = useAuthStatus();

  const unAuthorized = () => {
    window.location.assign("/");
  };

  return isLoggedin ? children : unAuthorized();
}
