import "./ModalPopUp.scss";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import Loader from "../Loader/Loader";

const ModalPopUp = ({
  show,
  submitAllAdsLoading,
  submitAllAdsSuccess,
  modalOkClick,
  submitAllAdsError,
  contentClassName,
}) => {
  return (
    <Modal
      // aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
    >
      <Modal.Body className="modBody">
        <div className={contentClassName}>
          {submitAllAdsLoading && (
            <>
              <p>In progress, Please wait ...</p>
              <Loader />
            </>
          )}
          {submitAllAdsSuccess && (
            <>
              <p className="success">{"Advertisements Updated Succesfully"}</p>
              <div className="btnContent">
                <Button className="mx-auto" onClick={modalOkClick}>
                  Ok
                </Button>
              </div>
            </>
          )}
          {submitAllAdsError && (
            <>
              <p className="failure">
                {"Sorry. Something went wrong. Please try again later "}
              </p>
              <div className="btnContent">
                <Button className="mx-auto" onClick={modalOkClick}>
                  Ok
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalPopUp;
