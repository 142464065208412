import { useState, useEffect } from "react";
import { formatDateWithMoment } from "../../Utils/Utils";

const Timer = () => {
  const [timeZone, setTimeZone] = useState("");

  const timeSetter = () => {
    const timeFetched = formatDateWithMoment(new Date());
    return timeFetched;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      const timeFetched = timeSetter();
      setTimeZone(timeFetched);
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  return <h6>{timeZone}</h6>;
};

export default Timer;
