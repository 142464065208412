import React from "react";
import DatePicker from "react-datepicker";
import 'react-datepicker/dist/react-datepicker.css';
import {currentDateAsTmrw} from "../../Utils/Utils";

const CalenderPick=({
  date,
  changeDate,
  id
})=>{
  return (
    <DatePicker
       selected={date} 
       onChange={changeDate} 
       showIcon
       id={id}
      //  icon={""}
      minDate={currentDateAsTmrw}
      dateFormat="dd/MM/yyyy"
    />
  );
}

export default CalenderPick; 
