import { all } from 'redux-saga/effects';
import fetchinitialmetadataSaga, {fetchregionlistSaga} from './fetchinitialmetadata/fetchinitialmetadataSaga';
import fetchedadsbypostingSaga from './fetchinitialmetadata/fetchedadsbypostingSaga';
import submitalladspositionSaga from './fetchinitialmetadata/submitalladspositionSaga';
import submitdbpositionSaga from './fetchinitialmetadata/submitdbpositionSaga';
import loginuserSaga from './loginuserSaga/loginuserSaga';
import editslugofadSaga from './fetchinitialmetadata/editslugofadSaga';

export default function* rootSaga(){
    yield all([
        fetchinitialmetadataSaga(),
        fetchregionlistSaga(),
        fetchedadsbypostingSaga(),
        submitalladspositionSaga(),
        submitdbpositionSaga(),
        loginuserSaga(),
        editslugofadSaga()
    ])
}