import './PagenotFound.scss';
const PagenotFound = () => {
  return (
    <div className='page-notfound'>
      <h1> 404 </h1>
      <p>Oops! The page you're looking for is not here.</p>{" "}
      <a href="/">Go Back to Home</a>
    </div>
  );
};
export default PagenotFound;
