import * as type from '../Constants/loginConstant';

const initialState = {
    loading: false,
    logStatus: false,
    userData: null,
    errorInLogin: null
}

export default function loginuser(state = initialState, action){
    switch(action.type){
        case type.USER_LOGIN:
            return {
                ...state,
                loading: true,
                userCredential: action?.payload
            }
        case type.USER_LOGIN_SUCCESS:
            localStorage.setItem('logStatus',true);
            localStorage.setItem('sid', action?.fetchedUserResult?.session_id)
            return {
                ...state,
                loading: false,
                logStatus: true,
                userData: action?.fetchedUserResult,
            }
        case type.USER_LOGIN_FAILED:
            return {
                ...state,
                loading: false,
                userData: null,
                logStatus: false,
                errorInLogin: action?.fetchedUserError
            }
        case type.USER_LOGIN_CLEANUP:
            localStorage.clear();
            return {
                ...state,
                loading: false,
                userData: null,
                logStatus: false,
                errorInLogin: null
            }
        default: 
            return state;
    }

}