import React from "react";
import { BsFiletypePdf } from "react-icons/bs";
import ReactToPrint from "react-to-print";

const PrintContent = ({
    fileName,
    refContainer,
    pageOptions
})=>(
<ReactToPrint
    bodyClass="print-agreement"
    documentTitle={fileName}
    content={()=> refContainer}
    trigger={()=> <BsFiletypePdf
        size={40}
    />}
    pageStyle={pageOptions}
/>)
export default PrintContent;