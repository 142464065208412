import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { AiOutlinePoweroff } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import NewsPaperContentGrid from "../NewsPaperContentGrid/NewsPaperContentGrid";
import FilterFetchForm from "../FilterFetch/FilterFetchForm";
import Timer from "../SharedComponent/Timer/Timer";
import AccordianTable from "../AccordianTable/AccordianTable";
import {
  fetchParamsListData,
  fetchRegionList,
  submitDbCleanUp,
} from "../ReduxState/Actions/actionAd";
import { userLogOut } from "../ReduxState/Actions/loginAction";
import { DEF_REGION } from "../CONSTANT";
import "./Scheduler.scss";

export default function Scheduler() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { regionDatas } = useSelector((state) => state.fetchinitialmetadata);
  const [regionId, setRegionId] = useState({
    label: "",
    value: "",
  });
  const [regionOption, setRegionOption] = useState([]);

  const signOutHandler = (e) => {
    e.preventDefault();
    dispatch(userLogOut());
    navigate("/");
  };

  const handleSelectRegion = (e) => {
    setRegionId(e);
    dispatch(submitDbCleanUp());
  };

  useEffect(() => {
    if (regionId && Object.keys(regionId).length && regionId?.value) {
      dispatch(fetchParamsListData(regionId?.value));
    }
  }, [dispatch, regionId]);

  useEffect(() => {
    if (regionDatas?.length) {
      const regOption = regionDatas?.map((el) => ({
        label: el?.name,
        value: el?.id,
      }));
      const defaultValRegion = regionDatas?.find(
        (el) => el?.name === DEF_REGION
      );
      setRegionId({
        label: defaultValRegion?.name,
        value: defaultValRegion?.id,
      });
      setRegionOption(regOption);
    }
  }, [regionDatas]);

  useEffect(() => {
    dispatch(fetchRegionList());
  }, [dispatch]);

  return (
    <>
      <div className="logScreenTop">
        <Timer />
        <span className="sinoutFont">
          <p>Sign out</p>
          <span title="SignOut " className="signoutIcon">
            <AiOutlinePoweroff size={20} onClick={signOutHandler} />
          </span>
        </span>
      </div>
      <div className="regionSelection">
        <Container>
          <Row>
            <Col md={6}>
              <div className="selectDrop  positionDrop mb-3">
                <h6>REGION</h6>
                <Select
                  name="position"
                  options={regionOption}
                  isSearchable={true}
                  placeholder={
                    <div>
                      <i className="fa fa-search"></i> Search position...
                    </div>
                  }
                  value={regionId}
                  onChange={(e) => handleSelectRegion(e)}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="tableAdsList">
        <AccordianTable regionVal={regionId}/>
      </div>
      <div className="filterFetchFormComponent">
        <FilterFetchForm regionVal={regionId} />
      </div>
      <div className="newsPaperContentGridContainer">
        <NewsPaperContentGrid regionVal={regionId} />
      </div>
    </>
  );
}
