import { useState } from "react";

const EditableInput = ({ name, placeholder, inputData, settledInputvalue }) => {
  const [input, setInput] = useState(inputData ? inputData : "");
  const changeHandler = (e) => {
    setInput(e?.target?.value);
    settledInputvalue(e?.target?.value);
  };

  return (
    <div>
      <input
        name={name}
        type="text"
        value={input}
        onChange={changeHandler}
        placeholder={!inputData ? placeholder : ""}
      />
    </div>
  );
};
export default EditableInput;
