import { memo } from "react";
import { CiCircleMinus , CiCirclePlus} from "react-icons/ci";


const PageCounter =({pageCountIncrDecrHandle, pageIncrCount})=>{
    return (
        <div className="pageIncrCount">
        <CiCircleMinus
          size={20}
          onClick={(e) => {
            pageCountIncrDecrHandle(e, "minus");
          }}
        />
        <span>{pageIncrCount}</span>
        <CiCirclePlus
          size={20}
          onClick={(e) => {
            pageCountIncrDecrHandle(e, "plus");
          }}
        />
      </div>
    )
}

export default memo(PageCounter); 