import * as type from "../Constants/adlist";

const dummyAdListData = [
  {
    id: 1,
    gridX: "",
    gridY: "",
    color: "yellow",
    name: "adList(1)",
    width: "100",
    height: "100",
  },
  {
    id: 2,
    gridX: "",
    gridY: "",
    color: "green",
    name: "adList(2)",
    width: "200",
    height: "100",
  },
  {
    id: 3,
    gridX: "",
    gridY: "",
    color: "blue",
    name: "adList(3)",
    width: "300",
    height: "100",
  },
];

const initialState = {
  metaDataAdlist: [...dummyAdListData],
  metaDataLoadingAdlist: false,
  metaDataErrorAdlist: null,
};
export default function adlisting(state = initialState, action) {
  switch (action.type) {
    case type.DEL_ONE_ADLIST_ADD_TO_AD_POSITION:
      const findIndexOfPayload = state?.metaDataAdlist?.findIndex(
        (el) => el?.id === action?.payload?.id
      );
      const updatedAdlist = [...state?.metaDataAdlist];
      updatedAdlist.splice(findIndexOfPayload, 1);
      return {
        ...state,
        metaDataAdlist: updatedAdlist,
      };
    case type.DEL_AD_POSITION_ADD_TO_ADLIST:
      return {
        ...state,
        metaDataAdlist: [...state?.metaDataAdlist, action?.payload],
      };
    default:
      return state;
  }
}
