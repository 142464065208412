import { call, put, takeEvery } from "redux-saga/effects";
import httpService from "../httpService";
import * as apiUrl from '../apiUrl';

async function editAdSlugNoApiCall(payload){
    try {
        const postPayload = {
            "id": payload?.id ?? "",
            "slug_number": payload?.slugNumber ?? ""
        }
        const response = await httpService.postCallMethod(apiUrl.editSlugNoUrl, postPayload);
        return response;
    } catch (error) {
        throw error;
    }
}

function* editAadSlugNo(action){
    try {
        const slugEditDataRegistry = yield call(()=> editAdSlugNoApiCall(action?.payload))
        if(slugEditDataRegistry?.status_code === 400){
            yield put({type : 'EDIT_SLUG_NO_AD_FAILED', slugEditErorRegistry: slugEditDataRegistry})
        }else {
            yield put({type: 'EDIT_SLUG_NO_AD_SUCCESS', slugEditDataRegistry: slugEditDataRegistry})
        }
    } catch (error) {
        yield put({type : 'EDIT_SLUG_NO_AD_FAILED', slugEditErorRegistry: error})
    }
}

function* editslugofadSaga(){
    yield takeEvery('EDIT_SLUG_NO_AD', editAadSlugNo)

}
export default editslugofadSaga;