import * as type from "../Constants/adlist";
import * as utilFunct from "../../Utils/Utils";
import * as constantVal from "../../CONSTANT";

const initailState = {
  pageHavingHeader: "", //withHeader-1 or else anything
  pageSize: {
    width: "",
    height: "",
  },
  adListedWithoutGrid: [], //adList
  adPositionedWithGrid: [], //adPositioned////➡️METASTATE
  //finalSubmiting
  adsMixinDataTransformedForPost: [], ///➡️onlyBACKUP_DATA_records which are sumited to backend
  submitAllAdsLoading: false,
  submitAllAdsSuccess: null,
  submitAllAdsError: null,
};

const appendPageCompatibilty = (adItem) => {
  //condin can be added in future in case if the ads to plcaexd in respective fiels page
  return 1;
};

const appendRequiredFieldsForModule = (filteredArray) => {
  //transformBackednd array to give componenets requirement
  const returnTransformedArray = filteredArray?.map((el) => {
    const [widthPx, heightPx] = utilFunct.getAdsSize(
      el?.size,
      constantVal.RATION_MAINNEWSGRID
    );
    const height = +heightPx; //👈SizeHeight
    const width = +widthPx; //👈SizeWidth
    const name = el?.position_name;
    const gridX = el?.dummy_chart_position_x
      ? Number(el?.dummy_chart_position_x)
      : 470;
    const gridY = el?.dummy_chart_position_y
      ? Number(el?.dummy_chart_position_y)
      : 0;
    const appendRequiredFieldForComponenets = {
      height,
      width,
      name,
      gridX,
      gridY,
    };

    const page = el?.page ? el?.page : appendPageCompatibilty(el);
    const _new_publish_date = el?.publish_date;
    const element = {
      ...el,
      ...appendRequiredFieldForComponenets,
      page,
      _new_publish_date,
    };
    return element;
  });
  return returnTransformedArray;
};

export default function dbpostadscombined(state = initailState, action) {
  switch (action?.type) {
    case type.MOVE_AD_FROM_TABLE_TO_AD_POSITIONER:
      //incase aded oneTableItem and again fetching filter happens means, if it one same page of position it should concat or else replace
      const pageDecider = action?.payload?.find((el) =>
        el?.page_name?.includes("1")
      );
      const samePage = state?.adPositionedWithGrid?.find((el) => {
        const findMatch =
          action?.payload?.find((tab) => tab?.page_id === el?.page_id) &&
          action?.payload?.find(
            (tab) => tab?.publish_date === el?.publish_date
          );
        return findMatch;
      });
      return {
        ...state,
        adPositionedWithGrid: samePage
          ? state?.adPositionedWithGrid?.concat(
              appendRequiredFieldsForModule(action?.payload)
            )
          : appendRequiredFieldsForModule(action?.payload),
        pageHavingHeader: pageDecider ? true : false,
      };

    case type.MOVE_AD_FROM_FILTERED_TO_AD_POSITIONER:
      const page_Decider = action?.payload?.find((el) =>
        el?.page_name?.includes("1")
      );

      const apiRes = action?.fetchedResults;
      //in case if in same page ads, only one ads having a template but other don't have at that time of fetch filterParams ads theer is one template mismatch issue will happen.. hence 👇
      // const oneOftheAdsHasTemplate = apiRes.find(ad => ad?.ad_template);
      // const transformedPayload = oneOftheAdsHasTemplate ?  apiRes.map(el=>({...el, ad_template: oneOftheAdsHasTemplate?.ad_template})) : apiRes

      return {
        ...state,
        adPositionedWithGrid: appendRequiredFieldsForModule(apiRes),
        pageHavingHeader: page_Decider ? true : false,
      };

    case type.REMOVE_A_AD_FROM_POSITIONER_SECTION:
      const findIndex = action?.payload?.updatingValues?.findIndex(
        (el) => el?.id === action?.payload?.deletingId
      );
      const updatedListGrid = [...action?.payload?.updatingValues];
      updatedListGrid.splice(findIndex, 1);
      const updatePageDecider = updatedListGrid?.find((el) =>
        el?.page_name?.includes("1")
      );
      // console.log('updatedListGrid', updatedListGrid, action?.payload)
      // console.log('action?.payload?.updatingValues', action?.payload?.updatingValues)

      return {
        ...state,
        adPositionedWithGrid: updatedListGrid,
        pageHavingHeader: updatePageDecider ? true : false,
      };

    //
    case type.SUBMITE_DB_POSITION:
      return {
        ...state,
        submitAllAdsLoading: true,
        adsMixinDataTransformedForPost: action?.payload,
      };
    case type.SUBMITE_DB_POSITION_SUCCESS:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: action?.successInSubmit,
        submitAllAdsError: null,
      };
    case type.SUBMITE_DB_POSITION_FAILED:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: action?.errorInSubmitting,
      };

    case type.SUBMITE_DB_CLEANUP:
      return {
        ...state,
        submitAllAdsLoading: false,
        submitAllAdsSuccess: null,
        submitAllAdsError: null,
        adPositionedWithGrid: [],
        adsMixinDataTransformedForPost: [],
      };

    default:
      return state;
  }
}
