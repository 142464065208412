import "./Login.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Button, InputGroup } from "react-bootstrap";
import { FaRegEyeSlash, FaRegEye } from "react-icons/fa";
import logoDesgn from "../../Assets/images/png/logoShanchaya.png";
import Loader from "../SharedComponent/Loader/Loader";
import { userLogingIn } from "../ReduxState/Actions/loginAction";
import { useDispatch, useSelector } from "react-redux";
import useFormValidation from "../CustomHooks/useFormValidation";

export default function Login() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, userData, errorInLogin } = useSelector(
    (state) => state.loginuser
  );
  const [visiblePassword, setVisiblePassword] = useState(false);
  const loginErrorSchema = {
    email: {
      type: "email",
      required: true,
      errorText: "Email or Username is required.",
    },
    password: {
      type: "password",
      required: true,
      errorText: "Password is required.",
    },
  };
  const loginInitialState = {
    email: "",
    password: "",
  };

  const { formValue, formError, formChange, formErrorChange } =
    useFormValidation(loginInitialState, loginErrorSchema);

  const submitHandler = async (e) => {
    e.preventDefault();
    const errorFound = formErrorChange();
    if (!errorFound?.email && !errorFound?.password) {
      const payload = {
        login: formValue?.email,
        password: formValue?.password,
      };
      dispatch(userLogingIn(payload));
    }
  };

  useEffect(() => {
    if (userData) {
      navigate("/schedulerchart");
    }
  }, [navigate, userData]);

  return (
    <div className="loginform">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 outerdiv loginscreen mx-auto mt-5">
            <div className="formContainer">
              <Form>
                {/* icon-person */}
                <div className="d-flex justify-content-center align-items-center">
                  <img src={logoDesgn} alt="eenadu-logo" height={"100px"} />
                </div>
                {/* email */}
                <Form.Group
                  sm="6"
                  className="mb-3 mt-1"
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    onChange={formChange}
                    type="email"
                    name="email"
                    placeholder="Email or Username *"
                    value={formValue?.email}
                    className="shadow-none"
                    isInvalid={!!formError?.email}
                    autoComplete="email"
                  />
                  <Form.Control.Feedback type="invalid">
                    {formError?.email}
                  </Form.Control.Feedback>
                </Form.Group>
                {/* password */}
                <Form.Group className="mb-3" controlId="formBasicPWord">
                  <InputGroup>
                    <Form.Control
                      onChange={formChange}
                      type={visiblePassword ? "text" : "password"}
                      name="password"
                      value={formValue?.password}
                      placeholder="Password *"
                      className="shadow-none"
                      isInvalid={!!formError?.password}
                    />
                    <InputGroup.Text
                      id="basic-addon2"
                      title="Click and hold to see typed password"
                      onMouseDown={() =>
                        formValue?.password ? setVisiblePassword(true) : false
                      }
                      onMouseUp={() =>
                        formValue?.password ? setVisiblePassword(false) : false
                      }
                    >
                      {visiblePassword ? <FaRegEye /> : <FaRegEyeSlash />}
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {formError?.password}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <div className={loading || errorInLogin ? "mx-auto" : ""}>
                  {loading ? (
                    <Loader />
                  ) : errorInLogin ? (
                    <span className="text-danger fw-bold">
                      {errorInLogin} <a href="/">Click to retry</a>
                    </span>
                  ) : (
                    <Button onClick={submitHandler}>SIGN IN</Button>
                  )}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
