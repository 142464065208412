export const templateOption = [
  { value: "template_1", label: "Template 1" },
  { value: "template_2", label: "Template 2" },
  { value: "template_3", label: "Template 3" },
  { value: "template_0", label: "No template" },
];

export const defInitialIncrCount=12;

export const pageNumberArray = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
  { value: "4", label: "4" },
  { value: "5", label: "5" },
  { value: "6", label: "6" },
  { value: "7", label: "7" },
  { value: "8", label: "8" },
  { value: "9", label: "9" },
  { value: "10", label: "10" },
  { value: "11", label: "11" },
  { value: "12", label: "12" },
];

export const editionTypeOption = [
  { value: "single", label: "Single" },
  { value: "twin", label: "Twin" },
];

export const paperNumberOption = [
  { value: "one", label: "One" },
  { value: "two", label: "Two" },
];

export const adsStructure = {
  adv_template_id:"",
  adv_template_name:"",
  ad_template: "",
  page: 1,
  ad_type: "",
  ad_type_id: "",
  dummy_chart_position_x: "",
  dummy_chart_position_y: "",
  edition_type: "single",
  gridX: "",
  gridY: "",
  height: "",
  id: "",
  name: "",
  original_publish_date: "",
  page_count: "",
  page_id: "",
  page_name: "",
  paper_number: "one",
  position_id: "",
  position_name: "",
  postponed_ad: "",
  publish_date: "",
  size: "",
  slug_number: "",
  width: "",
  _new_publish_date: "",
};

export const smTemplate = [
  { value: "template_1", label: "Template 1" },
  { value: "template_2", label: "Template 2" },
  { value: "template_3", label: "Template 3" },
  { value: "template_0", label: "No template" },
];
