import { useState } from "react";

/**
 * useFormValidation is a custom hook that provides form state management and validation functionality.
 * @param {Object} state - The initial state for the form.
 * @param {Object} errorSchema - Object containing error configuration for form fields.
 * @returns {Object} -  An object containing formValue, formError, formChange, and formErrorChange functions.
 */

const useFormValidation = (state, errorSchema) => {
  const [formValue, setFormValue] = useState(state);
  const [formError, setFormError] = useState({});

  const formChange = (e) => {
    const { name, value } = e.target;
    setFormValue((prev) => ({
      ...prev,
      [name]: value,
    }));
    setFormError(prev=>({
        ...prev,
        [name]: null
    }))
  };


  const formErrorChange = () => {
    const newErrorInForm = {};
    Object.entries(errorSchema)?.forEach(([key, value],i)=>{
        // eslint-disable-next-line no-unused-vars
        const {type, required, errorText} = value;
        const procuredValue = formValue[key];
        if(required){
            if(!procuredValue){
                newErrorInForm[key]= errorText;
            }else{
                newErrorInForm[key]= null;
            }
        }
        if(!required){
            newErrorInForm[key]= null;
        }
    })

    setFormError(newErrorInForm);
    return newErrorInForm;
  };

  return {
    formValue,
    formError,
    formChange,
    formErrorChange,
  };
};
export default useFormValidation;
