import * as type from "../Constants/adlist";

const dummy = [
  {
    id: 9,
    gridX: 396,
    gridY: 296,
    color: "rebeccapurple",
    name: "child1",
    width: "100",
    height: "200",
  },
  {
    id: 10,
    gridX: 0,
    gridY: 135,
    color: "green",
    name: "child2",
    width: "200",
    height: "100",
  },
  {
    //adlist

    id: 11,
    gridX: 470,
    gridY: 0,
    color: "cadetblue",
    name: "ad",
    width: "150",
    height: "50",
  },
  {
    id: 12,
    gridX: 224,
    gridY: 129,
    color: "red",
    name: "child2",
    width: "100",
    height: "300",
  },
  {
    id: 13,
    gridX: 296,
    gridY: 0,
    color: "pink",
    name: "child4",
    width: "200",
    height: "100",
  },
  {
    id: 14,
    gridX: 0,
    gridY: 0,
    color: "yellow",
    name: "child5",
    width: "200",
    height: "100",
  },
  {
    id: 15,
    gridX: 0,
    gridY: 446,
    color: "blue",
    name: "child6",
    width: "100",
    height: "50",
  },
];

const initialState = {
  metaDataAdPositioned: [...dummy],
  metaDataAdPositionedLoading: false,
  metaDataErrorAdPositioned: null,
};

export default function adpositioned(state = initialState, action) {
  switch (action.type) {
    case type.DEL_ONE_ADLIST_ADD_TO_AD_POSITION:
      return {
        ...state,
        metaDataAdPositioned: [...state?.metaDataAdPositioned, action?.payload],
      };
    case type.DEL_AD_POSITION_ADD_TO_ADLIST:
      const findIndexOfPayload = state?.metaDataAdPositioned?.findIndex(
        (el) => el?.id === action?.payload?.id
      );
      const updatedAdPositionedlist = [...state?.metaDataAdPositioned];
      updatedAdPositionedlist.splice(findIndexOfPayload, 1);
      return {
        ...state,
        metaDataAdPositioned: updatedAdPositionedlist,
      };
    default:
      return state;
  }
}
