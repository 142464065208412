import { useSelector } from "react-redux";
/**
 * useAuthStatus used to decide wheather user logged in or not. 
 * @returns {object} - returns an object containing loggedIn status and SesionId of user. 
 */
const useAuthStatus = () => {
  const { logStatus, userData } = useSelector((state) => state.loginuser);
  const isLoggedin = logStatus || JSON.parse(localStorage.getItem("logStatus"));
  const locStore = userData?.session_id || (localStorage.getItem("sid"));
  return {
    isLoggedin,
    sId: locStore,
  };
};
export default useAuthStatus;
