import * as type from '../Constants/adlist';


export function replaceAdListToAdPositioned(receivedLoad){
    return {
        type: type.DEL_ONE_ADLIST_ADD_TO_AD_POSITION,
        payload: receivedLoad
    }
}

export function replaceAdPositionedToAdList(receivedLoad){
    return {
        type : type.DEL_AD_POSITION_ADD_TO_ADLIST, 
        payload: receivedLoad
    }
}


export function fetchRegionList(){
    return {
        type: type.FETCH_REGIONS,
    }
}


export function fetchParamsListData(regionId){
    return {
        type : type.FETCH_INITIAL_PARAMS_DATA,
        payload: regionId
    }
}


export function fetchAdsListByPosting(paramsLoad){
    return {
        type: type.FETCH_ADS_BY_POST,
        payload: paramsLoad
    }
}


// PRIVATE_DB_MOVE_ADLIST_TO_AD_POSITION

export function privateDbMoveAdListToAdPositioned(adsListLoad){
    return {
        type: type.PRIVATE_DB_MOVE_ADLIST_TO_AD_POSITION,
        payload : adsListLoad
    }
}

export function privateDbMoveAdPositionedToAdList(adPositionedLoad){
    return{
        type: type.PRIVATE_DB_MOVE_AD_POSITION_TO_ADLIST,
        payload: adPositionedLoad
    }
}

export function privateDbSaveSubmitAllAds(adsArrays){
    return{
        type: type.PRIVATE_DB_SUBMIT_ADS_ALL_TYPES,
        payload: adsArrays
    }
}

export function cleanUpPrivateDbSucErrMsg(){
    return {
        type: type.PRIVATE_DB_SUBMIT_SUCCESS_ERROR_CLEANUP,
    }
}

//db
export function moveTableAdToPositioner(tableAd){
    return {
        type: type.MOVE_AD_FROM_TABLE_TO_AD_POSITIONER,
        payload : tableAd
    }
}
export function moveAdPositionerToTable(itemId){
    return {
        type: type.MOVE_AD_POSITIONER_TO_TABLE,
        payload : itemId
    }
}
export function sliceOneAdFromPositionerSection(item){
    return {
        type: type.REMOVE_A_AD_FROM_POSITIONER_SECTION,
        payload: item
    }
}
export function submitDbFinal(dataLoad){
    return {
        type: type.SUBMITE_DB_POSITION,
        payload: dataLoad
    }
}
export function submitDbCleanUp(){
    return {
        type: type.SUBMITE_DB_CLEANUP
    }
}

export function editSlugNo(slugAdPayload){
    return {
        type: type.EDIT_SLUG_NO_AD,
        payload: slugAdPayload
    }
}

export function editSlugNoCleanUp(){
    return {
        type: type.EDIT_SLUG_NO_AD_CLEANUP
    }
}