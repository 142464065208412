const convertIntoPx = (inc) => Number(inc) * 96;
const ratioCalculte = (dimXY, ratioNum) => Math.ceil(dimXY / ratioNum);

export const REAL_NEWS_PAPER_DIMENSION_INCH = "33x52";
export const RATIO_THUMBNAIL = 7;
export const RATION_MAINNEWSGRID = 3;

const [REAL_NEWS_WIDTH, REAL_NEWS_HEIGHT] =
  REAL_NEWS_PAPER_DIMENSION_INCH?.split(/[xX]/);
const width_px_news = convertIntoPx(REAL_NEWS_WIDTH);
const height_px_news = convertIntoPx(REAL_NEWS_HEIGHT);

export const NEWS_PAPER_WIDTH = ratioCalculte(
  width_px_news,
  RATION_MAINNEWSGRID
);
export const NEWS_PAPER_HEIGHT = ratioCalculte(
  height_px_news,
  RATION_MAINNEWSGRID
);

export const THUMBNAIL_SLIDER_WIDTH = ratioCalculte(
  NEWS_PAPER_WIDTH,
  RATIO_THUMBNAIL
);
export const THUMBNAIL_SLIDER_HEIGHT = ratioCalculte(
  NEWS_PAPER_HEIGHT,
  RATIO_THUMBNAIL
);

export const DEF_REGION = "Hyderabad";

export const ADTYPES_KEY = {
  nap: "nap",
  reta: "reta",
  classifieds: "classifieds",
};

export const ADTYPE_TITLE_TABLE = {
  nap: "NAP",
  reta: "RETA",
  classifieds: "Classifieds",
};
//NEWS_PAPER_HEIGHT &  NEWS_PAPER_WIDTH = 33x52 into 3163*4992  and 3: 1 . ie. val/3 == 1056 , 1664

//Total Page dimension = 33*52. It will come as 3168*4992 pixels. It will take more space. So you have to convert this as 3:1 Ratio. 1056*1664. or May be whatever ratio it is easy for you.
// So if half page jacket ad comes. I will give you as 33*25 you can convert as 1056*832 something like that.
