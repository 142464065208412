import './Loader.scss';
import React from 'react';
import {Blocks} from 'react-loader-spinner';


const Loader =()=>{
    return (
        <Blocks
        visible={true}
        height="50"
        width="50"
        ariaLabel="blocks-loading"
        wrapperStyle={{}}
        wrapperClass="blocks-wrapper"
        />
    )
}

export default Loader; 