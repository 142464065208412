import * as type from "../Constants/adlist";

const initialState = {
  slugEditLoading: false,
  slugEditSucceedData: null,
  slugEditError: null,
};

export default function editslugofad(state = initialState, action) {
  switch (action.type) {
    case type.EDIT_SLUG_NO_AD:
      return {
        ...state,
        slugEditLoading: true,
      };
    case type.EDIT_SLUG_NO_AD_SUCCESS:
      return {
        ...state,
        slugEditLoading: false,
        slugEditSucceedData: action?.slugEditDataRegistry,
        slugEditError: null,
      };
    case type.EDIT_SLUG_NO_AD_FAILED:
      return {
        ...state,
        slugEditLoading: false,
        slugEditSucceedData: null,
        slugEditError: action?.slugEditErorRegistry,
      };
    case type.EDIT_SLUG_NO_AD_CLEANUP:
      return {
        ...state,
        slugEditLoading: false,
        slugEditSucceedData: null,
        slugEditError: null,
      };

    default:
      return state;
  }
}
