import { useRef, useEffect, useState, useCallback, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "react-bootstrap";
import Select from "react-select";
import ModalPopUp from "../SharedComponent/ModalPopUp/ModalPopUp";
import SliderThumb from "../SharedComponent/SliderThumb/SliderThumb";
import CalenderPick from "../SharedComponent/CalenderPick/CalenderPick";
import PrintContent from "../SharedComponent/PrintContent/PrintContent";
import PageCounter from "../SharedComponent/PageCounter/PageCounter";
import {
  sliceOneAdFromPositionerSection,
  submitDbFinal,
  submitDbCleanUp,
  fetchParamsListData,
} from "../ReduxState/Actions/actionAd";
import * as constant from "../CONSTANT";
import {
  adsStructure,
  defInitialIncrCount,
  editionTypeOption,
  pageNumberArray,
  paperNumberOption,
  templateOption,
} from "../dataOption";
import "./NewsPaperContentGrid.scss";
import DraggableCard from "../SharedComponent/DraggableCard/DraggableCard";

const PAPER_WIDTH = constant.NEWS_PAPER_WIDTH;
const PAPER_HEIGHT = constant.NEWS_PAPER_HEIGHT;

const pageStyleOption = `
@page {
  size: ${PAPER_WIDTH}px ${PAPER_HEIGHT}px;
  margin: 0;
}
@media print {
  body {
    margin: 0;
    padding: 0;
  }
}
`;

const NewsPaperContentGrid = ({ regionVal }) => {
  const parentRef = useRef(null);
  const dispatch = useDispatch();
  const metaDataAdPositioned = useSelector(
    (state) => state.dbpostadscombined.adPositionedWithGrid
  );
  const { fetchMetaDataInitalDataParams } = useSelector(
    (state) => state.fetchinitialmetadata
  );
  const { submitAllAdsLoading, submitAllAdsSuccess, submitAllAdsError } =
    useSelector((state) => state.dbpostadscombined);
  const [draggableAdsPos, setDraggableAdsPos] = useState();
  const [dropDownTypeCriteria, setDropDownTypeCriteria] = useState({
    template: null,
    edition: null,
    page: null,
    paperNumber: null,
    pageNumber: null,
  });
  const [spaceAllocated, setSpaceAllocated] = useState({
    occupied: "",
    remaining: "",
  });
  const [pageNumberOptions, setPageNumberOptions] = useState([]);
  const [bookMarkOption, setBookMarkOption] = useState([]);
  const [bookMarkValue, setBookMarkValue] = useState({});
  const [initialIncrCount, setInitialIncrCount] = useState(defInitialIncrCount);
  const [pageIncrCount, setPageIncrCount] = useState(initialIncrCount);
  const [pageNumbersArrays, setPageNumbersArray] = useState(pageNumberArray);
  const defTemplateIfNoValue = templateOption[3];
  const defPageIfNoValue = pageNumbersArrays[0];
  const defEditionIfNoValue = editionTypeOption[0];
  const defPaperIFNoValue = paperNumberOption[0];
  const [datePostPoner, setDatePostPoner] = useState();
  const [toast, setToast] = useState(false);
  const [userClickedSlider, setUserClickedSlider] = useState(false);
  const [optionTemplate, setOptionTemplate] = useState([]);

  /**
   * check the ads having proper width and height
   * @param {Array<{objects}>} adsArry - accepts array of on objects , in this case draggableAdss
   * @returns {Boolean} - wheather the ads having proper height and width or not
   */
  const mimicAdsFunc = (adsArry) => {
    //in order to show blank slides even the ads not present
    const valueBoolean = adsArry?.some((ad) => ad?.width && ad?.height);
    return !valueBoolean;
  };

  const bookMarkChange = (val, key) => setBookMarkValue(val);

  const gridPositionValues = useCallback((value) => {
    // setting each element with theri grid positions
    if (value?.name) {
      setDraggableAdsPos((prev) =>
        prev?.map((el) => {
          if (el?.id === value?.name) {
            return {
              ...el,
              gridX: value?.x,
              gridY: value?.y,
            };
          } else {
            return {
              ...el,
            };
          }
        })
      );
    }
  },[]);

  const pageCountIncrDecrHandle = useCallback((e, opr) => {
    e.preventDefault();
    if (opr === "minus") {
      setPageIncrCount((prev) =>
        pageIncrCount <= defInitialIncrCount ? defInitialIncrCount : prev - 2
      );

      if (mimicAdsFunc(draggableAdsPos)) {
        // mimicAds
        const _transFormArray = [...draggableAdsPos];
        _transFormArray?.splice(-2, -2);
        setDraggableAdsPos(_transFormArray);
      }
    }
    if (opr === "plus") {
      setPageIncrCount((prev) => prev + 2);

      if (mimicAdsFunc(draggableAdsPos)) {
        // mimicAds
        const _transFormArray = [...draggableAdsPos];
        _transFormArray?.splice(
          draggableAdsPos?.length,
          0,
          { ...adsStructure, page: Number(draggableAdsPos?.length + 1) },
          { ...adsStructure, page: Number(draggableAdsPos?.length + 2) }
        );
        setDraggableAdsPos(_transFormArray);
      }
    }
  },[draggableAdsPos, pageIncrCount]);

  const openCloseToast = () => setToast((prev) => !prev);

  const modalOkClick = (e) => {
    e.preventDefault();
    dispatch(submitDbCleanUp()); // db
    setDraggableAdsPos([]);
    setInitialIncrCount(defInitialIncrCount); // pageIncrReset
    dispatch(fetchParamsListData(regionVal?.value)); //->to call api again to display updated tableData.
    openCloseToast();
  };

  const adsInsideNewsPaper = (arrList) => {
    const filterAccordingly = arrList?.filter(
      (el) => el?.page === dropDownTypeCriteria?.page?.value
    );
    const defLoadPageValue = "1"; //default pageNo should be assigned for 1 if the new ads not yet positoned
    const filterInSpecialCase = arrList?.filter(
      () => defLoadPageValue === dropDownTypeCriteria?.page?.value
    );
    const adsGotAssignedToPage = !!arrList?.find((el) => el?.page);
    return adsGotAssignedToPage ? filterAccordingly : filterInSpecialCase;
  };

  const dateValueFetched = (e) => {
    setDatePostPoner(e);
    const inputDate = new Date(e);
    const formattedDate = inputDate.toISOString().split("T")[0];
    setDraggableAdsPos((prev) =>
      prev?.map((el) => {
        if (el?.page === dropDownTypeCriteria?.page?.value) {
          return {
            ...el,
            _new_publish_date: formattedDate,
          };
        } else {
          return { ...el };
        }
      })
    );
  };

  const actionAdMovedToOtherPage = useCallback((arr) => {
    const _draggableAdsPos = [...draggableAdsPos];
    const index = _draggableAdsPos?.findIndex((item) => item?.id === arr?.id);
    _draggableAdsPos?.splice(index, 1, arr);
    setDraggableAdsPos(_draggableAdsPos);
  },[draggableAdsPos]);

  const submitActionDraggablePost = (e) => {
    e.preventDefault();
    openCloseToast();
    const condnCheckPostPoned = (originDate, postPoneDate) => {
      return JSON.stringify(originDate) === JSON.stringify(postPoneDate)
        ? false
        : true;
    };
    const arrayPositionedByUser = draggableAdsPos?.map((el) => {
      return {
        id: el?.id,
        adv_template_id: el?.adv_template_id,
        adv_template_name: el?.adv_template_name,
        ad_type_id: el?.ad_type_id,
        ad_type: el?.ad_type,
        position_id: el?.position_id,
        position_name: el?.position_name,
        size: el?.size,
        ad_template:
          el?.ad_template === templateOption[3].value ? "" : el?.ad_template,
        dummy_chart_position_x: el?.gridX,
        dummy_chart_position_y: el?.gridY,
        page_name: el?.page_name,
        page_id: el?.page_id,
        edition_type: dropDownTypeCriteria?.edition?.value,
        paper_number: dropDownTypeCriteria?.paperNumber?.value,
        page_count: pageIncrCount,
        page: el?.page,
        original_publish_date: el?.publish_date,
        publish_date: el?._new_publish_date,
        postponed_ad: condnCheckPostPoned(
          el?.publish_date,
          el?._new_publish_date
        ),
      };
    });

    dispatch(submitDbFinal(arrayPositionedByUser)); //db
  };

  const dropDownOnChangeEventhandler = (e, targetName) => {
    setDropDownTypeCriteria((prev) => ({
      ...prev,
      [`${targetName}`]: e,
    }));

    if (targetName === "template") {
      const _draggableAdsPos = [...draggableAdsPos];
      const _transformedList = _draggableAdsPos?.map((adList) => {
        if (adList?.page === dropDownTypeCriteria?.page?.value) {
          return {
            ...adList,
            adv_template_id: e?.value,
            adv_template_name: e?.label,
          };
        } else {
          return { ...adList };
        }
      });
      setDraggableAdsPos(_transformedList);
    }
  };

  const deleteTheChildrenInDrags = (e, childValues) => {
    e.preventDefault();
    const payload = {
      deletingId: childValues?.id,
      updatingValues: draggableAdsPos,
    };
    dispatch(sliceOneAdFromPositionerSection(payload)); //db
  };

  const selectionDoneFromSlider = useCallback((val = true) => {
    setUserClickedSlider(val);
  }, []);

  const callBackDropdownchangeHandler = useCallback(
    (fromSlider) => {
      const { page, template } = fromSlider;
      setDropDownTypeCriteria((prev) => ({
        ...prev,
        page,
        template:
          template ??
          optionTemplate?.find((option) => option?.template_default_all),
      }));
    },
    [optionTemplate]
  );

  useEffect(() => {
    setDraggableAdsPos(metaDataAdPositioned);
  }, [metaDataAdPositioned]);

  useEffect(() => {
    //sizeTo parent
    let parentContainerArea = 0;
    let occupiedSpace = 0;
    if (parentRef.current) {
      const containerWidth = PAPER_WIDTH;
      const containerHeight = PAPER_HEIGHT;
      parentContainerArea = containerWidth * containerHeight;
    }
    draggableAdsPos
      ?.filter((el) => el?.page === dropDownTypeCriteria?.page?.value)
      ?.forEach((el) => {
        const area = +el?.height * +el?.width;
        occupiedSpace += area;
      });

    const occupiedPercentage = (occupiedSpace / parentContainerArea) * 100;
    const remainingPercentage = 100 - occupiedPercentage;

    setSpaceAllocated((prev) => ({
      ...prev,
      // occupied: Math.round(occupiedSpace),
      occupied: Math.round(occupiedPercentage),
      // remaining: Math.round(remainingSpace)
      remaining: Math.round(remainingPercentage),
    }));
  }, [draggableAdsPos, dropDownTypeCriteria?.page?.value]);

  useEffect(() => {
    const pageNoTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "page_number"
    ]?.map((el) => ({
      label: el?.page_name,
      value: el?.id,
    }));

    const bookMarkTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "page_place_holder"
    ]?.map((el) => ({
      ...el,
      label: el?.name,
      value: el?.id,
    }));
    const optionTemplateTransform = fetchMetaDataInitalDataParams?.[0]?.[
      "adv_template"
    ]?.map((el) => ({
      ...el,
      label: el?.template_name,
      value: el?.template_id,
    }));
    setOptionTemplate(optionTemplateTransform);
    setPageNumberOptions(pageNoTransform);
    setBookMarkOption(bookMarkTransform);
  }, [fetchMetaDataInitalDataParams]);

  useEffect(() => {
    if (dropDownTypeCriteria?.edition?.value === "single") {
      setDropDownTypeCriteria((prev) => ({
        ...prev,
        [`paperNumber`]: paperNumberOption[0],
      }));
    }
  }, [dropDownTypeCriteria?.edition]);

  useEffect(() => {
    const templatePop = templateOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.ad_template === el?.value
      );
      return match;
    });

    const pagePop = pageNumbersArrays?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.page === el?.value
      );
      return match;
    });

    const editionPop = editionTypeOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.edition_type === el?.value
      );
      return match;
    });

    const paperPop = paperNumberOption?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.paper_number === el?.value
      );
      return match;
    });

    const pageNumberPop = pageNumberOptions?.find((el) => {
      const match = metaDataAdPositioned?.find(
        (item) => item?.page_id === el?.value
      );
      return match;
    });

    setDropDownTypeCriteria((prev) => ({
      ...prev,
      edition: editionPop ? editionPop : defEditionIfNoValue,
      page: pagePop ? pagePop : defPageIfNoValue,
      paperNumber: paperPop ? paperPop : defPaperIFNoValue,
      template: templatePop ? templatePop : defTemplateIfNoValue,
      pageNumber: pageNumberPop ? pageNumberPop : null,
    }));
  }, [
    defEditionIfNoValue,
    defPageIfNoValue,
    defPaperIFNoValue,
    defTemplateIfNoValue,
    metaDataAdPositioned,
    pageNumberOptions,
    pageNumbersArrays,
  ]);

  useEffect(() => {
    if (draggableAdsPos?.length) {
      const adTime = draggableAdsPos?.find(
        (el) => el?.page === dropDownTypeCriteria?.page?.value
      )?._new_publish_date;
      setDatePostPoner(adTime ? new Date(adTime) : new Date());
    }
  }, [dropDownTypeCriteria?.page?.value, draggableAdsPos]);

  useEffect(() => {
    if (!draggableAdsPos?.length) {
      setUserClickedSlider(false);
    }
  }, [draggableAdsPos]);

  useEffect(() => {
    // showcase / a mimic - for slider to have dummy ads in case if the ads are not present.
    if (!draggableAdsPos?.length) {
      //mimicAds
      const _arrayFillTrans = [...pageNumbersArrays]?.map((item, i) => ({
        ...adsStructure,
        page: Number(item?.value),
      }));
      setDraggableAdsPos(_arrayFillTrans);
    }
  }, [draggableAdsPos, pageIncrCount, pageNumbersArrays]);

  useEffect(() => {
    // load the pageCount in case if the ads got posted in the page after defInitialIncrCount i.e. initial count of page
    if (draggableAdsPos?.length) {
      const pageCountAmongAds = Math.max(
        ...draggableAdsPos?.map((el) => el.page_count)
      );
      setInitialIncrCount(
        pageCountAmongAds ? pageCountAmongAds : initialIncrCount
      );
    }
  }, [draggableAdsPos, initialIncrCount]);

  useEffect(() => {
    setPageIncrCount(initialIncrCount);
  }, [initialIncrCount]);

  useEffect(() => {
    const setPNoArraysByPageCount = () => {
      const fillArrObj = Array(pageIncrCount).fill()?.map((_, index)=>({ value: index+1, label: String(index+1) }))
      setPageNumbersArray(fillArrObj);
    };
    setPNoArraysByPageCount();
  }, [pageIncrCount]);

  return (
    <div className="newsGridWrapper">
      <ModalPopUp
        show={toast}
        contentClassName={"parentModalContentJsx"}
        modalOkClick={modalOkClick}
        submitAllAdsError={submitAllAdsError}
        submitAllAdsLoading={submitAllAdsLoading}
        submitAllAdsSuccess={submitAllAdsSuccess}
      />
      <>
        <div className="thumnailModule">
          <div>
            <SliderThumb
              newsPages={pageNumbersArrays}
              dropdownCategoryValues={dropDownTypeCriteria}
              dropDownChangeFromNewsPaperGrid={callBackDropdownchangeHandler}
              thumbNailAds={draggableAdsPos}
              userClickDone={userClickedSlider}
              functForUserClick={selectionDoneFromSlider}
              regionSlide={regionVal}
              smTemplate={optionTemplate}
            />
          </div>
        </div>
        <div
          className="newsFrame"
          style={{ display: userClickedSlider ? "block" : "none" }}
        >
          <div className="topMenuWrap">
            <div className="spaceDiv">
              Ad space: {spaceAllocated?.occupied}%, News Space:{" "}
              {spaceAllocated?.remaining}%
            </div>
            <div className="dropAndBtn">
              <div className="dropWrap">
                <div className="dropTitle">Bookmark page: </div>
                <div className="templateDrop editionTypeDrop bookmark">
                  <Select
                    options={bookMarkOption}
                    value={bookMarkValue}
                    onChange={(e) => bookMarkChange(e, "bookMark")}
                  />
                </div>
              </div>
              <div className="dropWrap">
                <div className="dropTitle">Page: </div>
                <div className="templateDrop editionTypeDrop">
                  <Select
                    options={pageNumbersArrays}
                    value={dropDownTypeCriteria?.page}
                    onChange={(e) => dropDownOnChangeEventhandler(e, "page")}
                  />
                </div>
              </div>
              <div className="dropWrap">
                <div className="dropTitle">Page Count: </div>
                <PageCounter
                  pageCountIncrDecrHandle={pageCountIncrDecrHandle}
                  pageIncrCount={pageIncrCount}
                />
              </div>
              <div className="dropWrap">
                <div className="dropTitle">Edition Type: </div>
                <div className="templateDrop editionTypeDrop">
                  <Select
                    options={editionTypeOption}
                    value={dropDownTypeCriteria?.edition}
                    onChange={(e) => dropDownOnChangeEventhandler(e, "edition")}
                  />
                </div>
              </div>
            </div>
            <div className="dropAndBtn">
              <div className="dropWrap">
                <div className="dropTitle">Paper Number: </div>
                <div className="templateDrop paperNumberDrop">
                  <Select
                    options={paperNumberOption}
                    value={dropDownTypeCriteria?.paperNumber}
                    onChange={(e) =>
                      dropDownOnChangeEventhandler(e, "paperNumber")
                    }
                    isDisabled={
                      dropDownTypeCriteria?.edition?.value === "single"
                        ? true
                        : false
                    }
                  />
                </div>
              </div>
              {
                <div className="dropWrap">
                  <div className="dropTitle">Template: </div>
                  <div className="templateDrop">
                    <Select
                      options={optionTemplate}
                      value={dropDownTypeCriteria?.template}
                      onChange={(e) =>
                        dropDownOnChangeEventhandler(e, "template")
                      }
                    />
                  </div>
                </div>
              }
              <div className="dropWrap">
                <div className="dropTitle">Postpone Date: </div>
                <div className="templateDrop">
                  <CalenderPick
                    id={"advertisementDate"}
                    date={datePostPoner}
                    changeDate={dateValueFetched}
                  />
                </div>
              </div>
              <div className="btnSubmit">
                <Button
                  disabled={
                    mimicAdsFunc(draggableAdsPos) || submitAllAdsLoading
                  }
                  onClick={submitActionDraggablePost}
                >
                  Submit Ads
                </Button>
              </div>
              <div className="pdfDownLoad" title="Save/print page">
                <PrintContent
                  fileName={`Schedule__${new Date().toLocaleDateString()}__PageNO:${
                    dropDownTypeCriteria?.page?.value
                  }`}
                  refContainer={parentRef?.current}
                  pageOptions={pageStyleOption}
                />
              </div>
            </div>
          </div>

          <div
            ref={parentRef}
            className="parent"
            id="parent"
            style={{
              position: "relative",
              width: `${PAPER_WIDTH}px`, // Adjust the width and height as needed
              height: `${PAPER_HEIGHT}px`,
            }}
          >
            <div className="templateDesign">
              {dropDownTypeCriteria?.template?.template_image && (
                <img
                  src={`data:image/png;base64,${dropDownTypeCriteria?.template?.template_image}`}
                  alt={dropDownTypeCriteria?.template?.template_name}
                ></img>
              )}
              {dropDownTypeCriteria?.template?.template_default_all && (
                <div className="pageNoLocation">
                  <div className="regn">{regionVal?.label}</div>
                  <div>{dropDownTypeCriteria?.page?.label}</div>
                </div>
              )}
            </div>

            {bookMarkValue?.value && (
              <div
                className="bookMarkOverPage"
                style={{
                  display: bookMarkValue?.value === "none" ? "none" : "block",
                }}
              >
                <p>{bookMarkValue?.label}</p>
              </div>
            )}

            {adsInsideNewsPaper(draggableAdsPos)?.map(
              (el, i) =>
                el?.width &&
                el?.height && (
                  <DraggableCard
                    childValues={el}
                    key={i}
                    reasignPosValues={gridPositionValues}
                    deleteChildDivs={deleteTheChildrenInDrags}
                    afterPageMovedArrUpdate={actionAdMovedToOtherPage}
                    pageNumbersArrays ={pageNumbersArrays}
                  />
                )
            )}
          </div>
        </div>
      </>
    </div>
  );
};

export default  memo(NewsPaperContentGrid);
