import * as type from '../Constants/adlist';

const initialState = {
    fetcheadsbypositingLoading : false,
    fetcheadsbypositingData: [],
    fetcheadsbypositingError: null
}

export default function fetcheadsbypositing(state = initialState, action){
    switch(action?.type){
        case type.FETCH_ADS_BY_POST: 
            return {
                ...state,
                fetcheadsbypositingLoading: true   
            }
        case type.FETCH_ADS_BY_POST_SUCCESS: 
            return {
                ...state,
                fetcheadsbypositingLoading: false,
                fetcheadsbypositingData: action?.fetcheadsbypositingDatas
            }
        case type.FETCH_ADS_BY_POST_FAILED: 
            return {
                ...state,
                fetcheadsbypositingLoading: false,
                fetcheadsbypositingError : action?.fetcheadsbypositingErrors
            }
        default :
            return state; 
        
    }

}