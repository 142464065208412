import {combineReducers} from 'redux';
import adlisting from './adlisting';
import adpositioned from './adpositioned';
import fetchinitialmetadata from './fetchinitialmetadata';
import fetcheadsbypositing from './fetchedadsbyposting';
import privatedbstate from './privatedbstate'; 
import dbpostadscombined from './dbpostadscombined';
import loginuser from './loginuser';
import editslugofad from './editslugofad';

const rootReducer = combineReducers({
    adlisting: adlisting, 
    adpositioned: adpositioned,
    fetchinitialmetadata: fetchinitialmetadata,
    fetcheadsbypositing: fetcheadsbypositing,
    privatedbstate: privatedbstate,
    dbpostadscombined: dbpostadscombined,
    loginuser: loginuser,
    editslugofad: editslugofad
})

export default rootReducer; 
