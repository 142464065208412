import { useRef } from "react";

const TemplateThumbanails = ({ propTemplate, adsRef, location, page_No }) => {
  const containerRef = useRef(null);
  return (
    <>
      <div className="thumb_templates" ref={containerRef} id="containerThumb">
        <div className="templateDesign">
          {propTemplate?.template_image && (
            <img
              src={`data:image/png;base64,${propTemplate?.template_image}`}
              alt={propTemplate?.template_name}
            />
          )}
          {
            propTemplate?.template_default_all &&
            <div className="pageNoLocation">
              <div className="regn">{location?.label}</div>
               <div>{page_No}</div>
            </div>
          }
        </div>
      </div>
    </>
  );
};

export default TemplateThumbanails;
