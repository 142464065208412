import { createStore, applyMiddleware, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from "./Reducer";
import rootSaga from '../ReduxSaga';

const sagaMiddleware = createSagaMiddleware();

const Store = compose(
    applyMiddleware(sagaMiddleware),
)(createStore)(rootReducer);

sagaMiddleware.run(rootSaga);

export default Store; 

